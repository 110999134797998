var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Clientes com TDE")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'Customers.index' })}}},[_vm._v("Voltar")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary","disabled":_vm.loading},on:{"click":function($event){return _vm.print(false)}}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" Imprimir ")],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","disabled":_vm.loading},on:{"click":function($event){return _vm.print(true)}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Baixar ")],1),_c('v-data-table',{attrs:{"items":_vm.customers,"headers":[
      { text: 'Cliente', value: 'name' },
      { text: 'CNPJ', value: 'cnpj' },
      { text: 'TDE', value: 'tde' } ],"dense":"","disable-pagination":"","hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.cnpj",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.fCnpj(item.cnpj)))])]}},{key:"item.tde",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$functions.moneyFormat(item.tde)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }