<template>
  <div>
    <h1>Clientes com TDE</h1>

    <v-btn
      color="error"
      @click="$router.push({ name: 'Customers.index' })"
      class="ma-2"
      >Voltar</v-btn
    >

    <v-btn
      color="secondary"
      @click="print(false)"
      class="ma-2"
      :disabled="loading"
    >
      <v-icon>mdi-printer</v-icon>
      Imprimir
    </v-btn>

    <v-btn
      color="success"
      @click="print(true)"
      class="ma-2"
      :disabled="loading"
    >
      <v-icon>mdi-download</v-icon>
      Baixar
    </v-btn>

    <v-data-table
      :items="customers"
      :headers="[
        { text: 'Cliente', value: 'name' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'TDE', value: 'tde' },
      ]"
      dense
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:[`item.cnpj`]="{ item }">
        <span>{{ fCnpj(item.cnpj) }}</span>
      </template>
      <template v-slot:[`item.tde`]="{ item }">
        <span>{{ $functions.moneyFormat(item.tde) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import fn from "@/modules/functions";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "../../../assets/logo_sem_fundo.png";

export default {
  data() {
    return {
      customers: [],
      loading: false,
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    fCnpj(value) {
      if (value) {
        if (value.length === 14) {
          return value.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          );
        } else {
          return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
      } else {
        return "Vazio";
      }
    },

    async getCustomers() {
      this.loading = true;

      try {
        let { data } = await this.$http.get(`api/customers-with-tde`);
        this.customers = data;
      } catch (error) {
        console.log(error);
        this.$toast.error("Erro ao carregar lista");
      }

      this.loading = false;
    },

    printData() {
      let data = [];
      this.customers.map((customer) => {
        data.push([
          fn.cutName(customer.name, 40),
          this.fCnpj(customer.cnpj),
          fn.moneyFormat(customer.tde),
        ]);
      });

      return data;
    },

    print(download) {
      let doc = new jsPDF({ orientation: "portrait" });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(24);

      doc.addImage(logo, "JPEG", 66, -5, 80, 80);
      doc.text("Clientes com TDE", 105, 60, null, null, "center");

      doc.autoTable({
        head: [["Nome", "CNPJ", "TDE"]],
        body: [...this.printData()],
        startY: 70,
      });

      if (download) {
        doc.save(`clientes-com-tde`);
        return;
      }

      doc.setProperties({
        title: `clientes-com-tde`,
      });
      doc.output("dataurlnewwindow");
    },
  },
};
</script>